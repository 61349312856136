@tailwind base;
@tailwind components;
@tailwind utilities;

*{
	scroll-behavior: smooth;
	font-family: 'DM Sans', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
}

 
::-webkit-scrollbar-track {
    background-color: transparent;
	margin: 30px 0;
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px 0 0 10px;
}

.profile::-webkit-scrollbar {
    width: 10px;
}
 
.profile::-webkit-scrollbar-track {
    background-color: transparent;
	margin: 30px 0;
}
 
.profile::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

.home::-webkit-scrollbar {
  width: 5px;
}

.home::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 30px 0;
}

.home::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}




.marquee {
  display: flex;
  overflow: hidden;
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}